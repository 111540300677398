// src/pages/ComingSoon.js
import React from 'react';

const ComingSoon = () => {
  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      flexDirection: 'column',
      fontFamily: 'Arial, sans-serif',
      backgroundColor: '#f0f0f0',
      color: '#333',
      textAlign: 'center',
    },
    heading: {
      fontSize: '48px',
      marginBottom: '20px',
    },
    subheading: {
      fontSize: '24px',
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Coming Soon</h1>
      <p style={styles.subheading}>This page is under construction. Stay tuned!</p>
    </div>
  );
};

export default ComingSoon;
